// @ts-check
import qs from "qs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CURRENT_STOCK } from "../dtos/DTOCurrentStock";
import { Filter } from "../shared/QueryHelpers";
import { useApi } from "../shared/useApi";

export const TIRE_TESTS = "tire-tests";

/** @typedef {("pending" | "completed" | "failed")} TireTestStageStatus */
/**
 * @typedef TireTestStage
 * @property {TireTestStageStatus} status
 * @property {string} [runReference]
 * @property {string} [comments]
 * @property {number} [completion]
 * @property {number} [outboardTemperatureInCelsius]
 * @property {number} [centerTemperatureInCelsius]
 * @property {number} [inboardTemperatureInCelsius]
 * @property {number} [shoulderTemperatureInCelsius]
 * @property {TireTestFile[]} [files]
 */

/** @typedef {("pending" | "completed" | "finished" | "failed")} TireTestRegderStatus */
/**
 * @typedef TireTestRegder
 * @property {TireTestRegderStatus} status
 * @property {string} [runReference]
 * @property {string} [comments]
 * @property {number} [completion]
 * @property {TireTestFile[]} [files]
 */

/**
 * @typedef TireTestFile
 * @property {string} oid
 * @property {string} name
 * @property {string} type
 */

/**
 * @typedef Defect
 * @property {string} title
 * @property {string} description
 * @property {boolean} checked
 * @property {string} comments
 * @property {TireTestFile[]} files
 */

/** @typedef {("draft" | "ready" | "planned" | "tested" | "analysed" | "archived")} TireTestStatus */
/**
 * @typedef TireTestProps
 * @property {string} [_id] The tire test server id. Will be completed when synchronized with server.
 * @property {string} [oid] The tire test identifier, might be undefined before inserted into IndexedDB
 * @property {TireTestStatus} [status] The tire test status. Will be updated automatically when fields are completed.
 * @property {boolean} [isLocked] The tire test locked property.
 * @property {import("../shared/AuditHelpers").User} [developer] The developer of the tire test.
 * @property {string} [type]
 * @property {string} [dueDate]
 * @property {string} [project]
 * @property {string} [testMachine]
 * @property {string} [championship]
 * @property {string} [category]
 * @property {string} [circuit]
 * @property {string} [team]
 *
 * @property {string} [dto]
 * @property {string} [tireRim]
 * @property {string} [dtoComplement]
 * @property {boolean} [isAlreadyTested]
 * @property {string} [circuitSignalReference]
 * @property {string} [dxp]
 * @property {"FrontLeft" | "FrontRight" | "RearLeft" | "RearRight" } [positionOnVehicle]
 * @property {string} [testDevelopmentComments]
 * @property {number} [fzUserMarginInPercent]
 * @property {number} [fzPeakInNewtons]
 * @property {number} [fyUserMarginInPercent]
 * @property {number} [fxUserMarginInPercent]
 * @property {number} [speedAverageUserMarginInKilometersPerHour]
 * @property {number} [speedMaxUserMarginInKilometersPerHour]
 * @property {boolean} [isREGDER]
 * @property {number} [pressureInBars]
 * @property {number} [pressureDeltaInBars]
 * @property {string} [pressureEvolutionInBars]
 * @property {number} [staticCamberInDegrees]
 * @property {number} [distanceInKilometers]
 * @property {number} [lapCount]
 * @property {number} [requestedStageCount]
 * @property {string} [runfileName]
 *
 * @property {string} [session]
 * @property {number} [priority]
 *
 * @property {string} [serialNumber]
 * @property {string} [machinePosition]
 * @property {TireTestStage[]} [stages]
 * @property {TireTestRegder[]} [regders]
 * @property {string} [regderFinalCoefficient]
 * @property {string} [testedDate]
 *
 * @property {number} [blister]
 * @property {number} [wearOutboard]
 * @property {number} [wearCenter]
 * @property {number} [wearInboard]
 * @property {boolean} [nothingToDeclare]
 * @property {string} [testAnalysisComments]
 * @property {TireTestFile[]} [files]
 * @property {Defect[]} [defects]
 * @property {string} [parent]
 * @property {string[]} [children]
 *
 * @property {object} [meta]
 * @property {import("../dtos/DTOQueries").DTO} [meta.dto]
 * @property {import("../tires/TireQueries").Tire} [meta.tire]
 * @property {import("../sessions/SessionQueries").Session} [meta.session]
 * @property {import("../dxps/DXPQueries").DXP} [meta.dxp]
 */
/** @typedef {import("../shared/AuditHelpers").AuditedResource & TireTestProps} TireTest */

/** @typedef {(searchParams?: import("../shared/QueryHelpers").SearchParams<TireTest>, selectedStatus?: string, isTreeView?: boolean) => import("react-query").UseQueryResult<import("../shared/QueryHelpers").Page<TireTest>>}} */
export const useTireTestsQuery = (searchParams = {}, selectedStatus, isTreeView, isEnabled = true) => {
  const api = useApi();
  return useQuery(
    [TIRE_TESTS, searchParams],
    async () => {
      const { limit = 0, skip = 0, sort = "_id", ...query } = searchParams;
      const response = await api.get(`v1/tire-tests`, {
        searchParams: qs.stringify({
          limit,
          skip,
          sort,
          ...Filter.and(query, { status: { $ne: "archived" } }),
        }),
      });
      const totalCount = Number(response.headers.get("X-Total-Count"));
      let list = await response.json();

      list = list.filter(
        (el) =>
          !selectedStatus ||
          (selectedStatus &&
            ((!isTreeView && el.status === selectedStatus) ||
              (isTreeView && el.status === selectedStatus && !el.parent) ||
              el.children.length > 0))
      );

      return {
        totalCount,
        list,
      };
    },
    { enabled: isEnabled }
  );
};

/** @typedef {(searchParams?: import("../shared/QueryHelpers").SearchParams<TireTest>, selectedStatus?: string, isTreeView?: boolean) => import("react-query").UseQueryResult<import("../shared/QueryHelpers").Page<TireTest>>}} */
export const useTireTestsCountQuery = (searchParams = {}, selectedStatus, isTreeView) => {
  const api = useApi();
  return useQuery(["tire-tests-count", searchParams], async () => {
    const { limit = 0, skip = 0, sort = "_id", ...query } = searchParams;
    const response = await api.get(`v1/tire-tests`, {
      searchParams: qs.stringify({
        limit,
        skip,
        sort,
        ...Filter.and(query, { status: { $ne: "archived" } }),
      }),
    });
    const totalCount = Number(response.headers.get("X-Total-Count"));
    let list = await response.json();

    return {
      totalCount,
      list,
    };
  });
};

/** @type {(tireTestId: string) => import("react-query").UseQueryResult<TireTest>} */
export const useTireTestByIdQuery = (tireTestId) => {
  const api = useApi();
  return useQuery([TIRE_TESTS, tireTestId], async () => {
    return api.get(`v1/tire-tests/${tireTestId}`).json();
  });
};

/** @type {(stage: TireTestStage) => boolean} */
const isPendingStage = (stage) => {
  return !stage.status || stage.status === "pending";
};

/** @type {(stages: TireTestStage[]) => number} */
export const getNextAvailableStageIndex = (stages) => {
  return stages.findIndex((stage, index, stages) => {
    if (index === 0 && isPendingStage(stage)) {
      return true;
    }
    if (index !== 0 && isPendingStage(stage) && stages[index - 1].status === "completed") {
      return true;
    }
    return false;
  });
};

/** @type {(regder: TireTestRegder) => boolean} */
const isPendingRegder = (regder) => {
  return !regder.status || regder.status === "pending";
};

/** @type {(regders: TireTestRegder[]) => number} */
export const getNextAvailableRegderIndex = (regders) => {
  return regders.findIndex((regder, index, regders) => {
    if (index === 0 && isPendingRegder(regder)) {
      return true;
    }
    if (index !== 0 && isPendingRegder(regder) && regders[index - 1].status === "completed") {
      return true;
    }
    return false;
  });
};

/** @type {() => import("react-query").UseMutationResult<TireTest, unknown, TireTest>} */
export const useUpsertTireTestMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (tireTest) => {
      if (tireTest?._id) {
        return api.patch(`v1/tire-tests/${tireTest._id}`, { json: tireTest }).json();
      }
      return api.post(`v1/tire-tests`, { json: tireTest }).json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TIRE_TESTS);
        queryClient.invalidateQueries("tire-tests-count");
        // Invalidate stock as it might change when a tire test DTO changes
        // e.g.: when a DTO is assigned to a tire test or changes, the stock will be decremented
        queryClient.invalidateQueries(CURRENT_STOCK);
      },
    }
  );
};

/** @type {() => import("react-query").UseMutationResult<TireTest[], unknown, TireTest[]>} */
export const useUpsertManyTireTestMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (tireTests) => {
      return Promise.all(
        tireTests.map((tireTest) => {
          if (tireTest?._id) {
            return api.patch(`v1/tire-tests/${tireTest._id}`, { json: tireTest }).json();
          }
          return api.post(`v1/tire-tests`, { json: tireTest }).json();
        })
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(TIRE_TESTS);
        queryClient.invalidateQueries("tire-tests-count");
        queryClient.invalidateQueries('tire-test-children');
        queryClient.invalidateQueries(CURRENT_STOCK);
      },
    }
  );
};

const defaultKeysToKeep = [
  "type",
  "championship",
  "developer",
  "category",
  "project",
  "testMachine",
  "circuit",
  "team",
  "dto",
  "tireRim",
  "dtoComplement",
  "isAlreadyTested",
  "circuitSignalReference",
  "dxp",
  "positionOnVehicle",
  "testDevelopmentComments",
  "fzUserMarginInPercent",
  "fzAverageTotal",
  "fzMaxTotal",
  "fzPeakInNewtons",
  "fyUserMarginInPercent",
  "fyMaxTotal",
  "fyAverageTotal",
  "fxUserMarginInPercent",
  "fxAverageTotal",
  "fxMaxTotal",
  "speedAverageUserMarginInKilometersPerHour",
  "totalVelocityAverage",
  "speedMaxUserMarginInKilometersPerHour",
  "totalVelocityMax",
  "pressureInBars",
  "staticCamberInDegrees",
  "totalStaticCamber",
  "totalMaxCamber",
  "totalAverageCamber",
  "totalMinCamber",
  "distanceInKilometers",
  "requestedStageCount",
];

/** @type {() => import("react-query").UseMutationResult<TireTest, unknown, { tireTestId: string, keysToKeep?: string[], defaultProps?: Partial<TireTest> }>} */
export const useDuplicateTireTestMutation = () => {
  const api = useApi();
  const { mutateAsync: upsertTireTest } = useUpsertTireTestMutation();
  return useMutation(async ({ tireTestId, keysToKeep = defaultKeysToKeep, defaultProps = {} }) => {
    const tireTestToDuplicate = await api.get(`v1/tire-tests/${tireTestId}`).json();
    const isChildTest = !!tireTestToDuplicate.parent;
    // If the cloned test is a child test, keep the session, the due date, and the parent test
    keysToKeep = isChildTest ? [...keysToKeep, "session", "dueDate", "parent"] : keysToKeep;

    const isWitnessTire = tireTestToDuplicate.meta.tire?.isWitnessTire;

    keysToKeep = isWitnessTire ? [...keysToKeep, "dueDate", "pressureDeltaInBars", "isREGDER", "pressureEvolutionInBars", "lapCount", "runfileName", "session", "serialNumber", "tirf", "machinePosition", "status"] : keysToKeep;

    // Create an empty tire test and update its values.
    // The tire tests properties will be computed properly (e.g.: status and DTO stock movement)
    const duplicatedTireTest = await upsertTireTest({});
    // @ts-ignore
    const duplicatedProperties = Object.fromEntries(
      Object.entries(tireTestToDuplicate).filter(([key]) => keysToKeep.includes(key))
    );
    return upsertTireTest({
      _id: duplicatedTireTest._id,
      ...duplicatedProperties,
      ...(isWitnessTire && {priority: 0}),
      ...defaultProps,
    });
  });
};

/** @type {() => import("react-query").UseMutationResult<TireTest, unknown, { tireTestId: string }>} */
export const useDuplicateAsChildTireTestMutation = () => {
  const { mutateAsync: duplicateTireTest } = useDuplicateTireTestMutation();
  return useMutation(async ({ tireTestId }) => {
    return duplicateTireTest({
      tireTestId,
      keysToKeep: [...defaultKeysToKeep, "session", "dueDate"],
      defaultProps: {
        parent: tireTestId,
      },
    });
  });
};
