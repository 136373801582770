import { Editor } from "@tinymce/tinymce-react";

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from "tinymce/tinymce";

// Theme
import "tinymce/themes/silver";
// Toolbar icons
import "tinymce/icons/default";
// Editor styles
import "tinymce/skins/ui/oxide/skin.min.css";

// importing the plugin js.
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/lists";
import "tinymce/plugins/charmap";
import "tinymce/plugins/hr";
import "tinymce/plugins/anchor";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/table";
import "tinymce/plugins/template";
import "tinymce/plugins/help";
import { forwardRef, useState } from "react";
import Compressor from "compressorjs";

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */


const TinyEditorComponent = forwardRef(({ initialValue, ...props }, ref) => {

  const [initialContent, setInitialContent] = useState(null);

  return (
    <>
      <Editor
        onLoadContent={() => {
          setInitialContent(initialValue);
        }}
        onInit={(evt, editor) => ref.current = editor}
        initialValue={initialContent}
        init={{
          height: 300,
          min_height: 300,
          plugins: ["lists imagetools paste table"],
          table_toolbar:
            "tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
          toolbar:
            "undo redo | fontselect | fontsizeselect | lineheight |" +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat",
          image_title: true,
          object_resizing: "true",
          automatic_uploads: true,
          file_picker_types: "image",
          content_css: "default",
          imagetools_cors_hosts: [
            "localhost:3000",
            "tem-dev.michelin.net",
            "tem-indus.michelin.net",
            "tem.michelin.net",
          ],
          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.onchange = function () {
              var file = this.files[0];

              new Compressor(file, {
                size: 4,
                quality: 0.6,
                maxWidth: 400,
                maxHeight: 400,
                resize: "none",

                success(result) {
                  var reader = new FileReader();
                  reader.onload = function () {
                    var id = "blobid" + new Date().getTime();
                    var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                    var base64 = reader.result.split(",")[1];
                    var blobInfo = blobCache.create(id, result, base64);
                    blobCache.add(blobInfo);

                    cb(blobInfo.blobUri(), { title: result.name });
                  };
                  reader.readAsDataURL(result);
                },
              });
            };

            input.click();
          },
        }}
      />
    </>
  );
});

export default TinyEditorComponent;
