import { useController } from "react-hook-form";
import BaseCreatableSelect from "react-select/creatable";
import { useAutocompleteStyles } from "../shared/Autocomplete";
import { useFormId, useFormName } from "../shared/Form";
import { useDTOsQuery } from "./DTOQueries";

export const RimAutocomplete = ({ defaultValue = "", rules, onFocus, ...props }) => {
  const name = useFormName();

  const { isLoading, data } = useDTOsQuery();

  // Register react-hook-form field controller
  const {
    field: { value, onChange, ...controllerProps },
  } = useController({
    name,
    rules,
    defaultValue,
    onFocus,
  });

  const uniqDataList = data && data.list ? [...new Set( data.list.map((dto) => {
    return dto.tireRim;
  }))].sort((a, b) => a.localeCompare(b)) : [];

  // Resolve option even when entries are not loaded from the Dimension collection.
  // Use the value as a label. If no value is defined, no element is selected
  const selectedOption = value ? { value, label: value } : null;
  const options = uniqDataList ? uniqDataList.filter((tireRim) => tireRim && tireRim.length > 0).map((tireRim) => ({ _id: tireRim, label: tireRim, value: tireRim })) : [];

  // Change the value of the field
  const handleChange = (selectedOption) => onChange(selectedOption ? selectedOption.value : null);


  // Pass the input value to the modal and open it
  const onCreateOption = (value) => {
    onChange(value);
    data.list.push({tireRim: value});
  };

  const inputId = useFormId();

  const styles = useAutocompleteStyles();

  return (
    <>
      <BaseCreatableSelect
        inputId={inputId}
        value={selectedOption}
        onChange={handleChange}
        styles={styles}
        isClearable
        isLoading={isLoading}
        isDisabled={isLoading}
        options={options}
        onCreateOption={onCreateOption}
        {...controllerProps}
        {...props}
      />
    </>
  );
};
