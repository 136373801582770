import { useEffect, useRef, useState } from "react";
import {
  Notification,
  NotificationContent,
  NotificationDescription,
  NotificationSpinner,
  NotificationSuccessIcon,
  NotificationTitle
} from "./Notification";
import { useNotificationContext } from "./NotificationProvider";

const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

/** @type {(result: import("react-query").MutationStatus) => { setTitle, setDescription }} */
export const useMutationStatusNotification = (status) => {
  const previousStatus = usePrevious(status);
  const { onOpen, onClose, onUpdate } = useNotificationContext();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const notificationId = useRef(null);
  useEffect(() => {
    if (status === "idle") {
      return;
    }
    const content = (
      <Notification onDismiss={() => onClose(notificationId.current)}>
        {status === "loading" && <NotificationSpinner />}
        {status === "success" && <NotificationSuccessIcon />}
        <NotificationContent>
          <NotificationTitle>{title}</NotificationTitle>
          <NotificationDescription>{description}</NotificationDescription>
        </NotificationContent>
      </Notification>
    );
    if (previousStatus !== status) {
      onClose(notificationId.current);
      notificationId.current = onOpen(content, status === "success" ? 3000 : undefined);
    } else {
      onUpdate(notificationId.current, content);
    }
  }, [description, onClose, onOpen, onUpdate, previousStatus, status, title]);

  return { setTitle, setDescription };
};
