/** @jsxImportSource @emotion/react */
import { FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";
import { Redirect } from "react-router-dom";
import "twin.macro";
import { PrimaryButton } from "../shared/Button";
import {
  Checkbox,
  CheckboxHelperText,
  FieldHelperText,
  FormGroup,
  InlineFormGroup,
  Input,
  Label,
  MaskedInput,
  OptionalLabel,
  Select,
} from "../shared/Form";
import { Modal, ModalActions, ModalButton } from "../shared/Modal";
import { Panel, PanelContent, PanelFooter } from "../shared/Panel";
import { useFetchDTOs, useUpsertDTOMutation } from "./DTOQueries";
import { DimensionAutocomplete } from "./DimensionAutocomplete";
import { RimAutocomplete } from "./RimAutocomplete";

const DTOFormFields = (props) => {
  const { register } = useFormContext();
  const fetchDTOs = useFetchDTOs();
  const id = useWatch({ name: "_id" });
  return (
    <div tw="grid grid-cols-1 gap-6" {...props}>
      <input type="hidden" name="_id" ref={register()} />
      <input type="hidden" name="status" ref={register()} defaultValue="active" />
      <FormGroup name="value">
        <Label>DTO</Label>
        <MaskedInput
          placeholder="A999999A"
          mask="a999999a"
          maskChar={null}
          rules={{
            required: "This field is required",
            pattern: {
              value: /^[A-Z][0-9]{6}[A-Z]$/,
              message: "Invalid DTO format (A999999A expected)",
            },
            validate: async (value) => {
              const dtos = await fetchDTOs({
                value,
                ...(id && { _id: { $ne: id } }),
              });
              return dtos.length === 0 ? null : "This DTO already exists";
            },
          }}
          beforeMaskedValueChange={(newState) => {
            newState.value = newState.value.toUpperCase();
            return newState;
          }}
        />
        <FieldHelperText />
      </FormGroup>

      <FormGroup name="tireDimension">
        <Label>Dimension</Label>
        <DimensionAutocomplete rules={{ required: "This field is required" }}/>
        <FieldHelperText />
      </FormGroup>

      <FormGroup name="tireRim">
        <Label>Rim</Label>
        <RimAutocomplete rules={{ required: "This field is required" }} />
        <FieldHelperText />
      </FormGroup>

      <FormGroup name="casingReference">
        <div tw="flex justify-between">
          <Label>Casing reference</Label>
          <OptionalLabel />
        </div>
        <Input />
      </FormGroup>

      <FormGroup name="kmCompound">
        <Label>KM Coumpound</Label>
        <Select>
          <option value="Soft">Soft</option>
          <option value="Medium">Medium</option>
          <option value="Hard">Hard</option>
        </Select>
        <FieldHelperText />
      </FormGroup>

      <InlineFormGroup name="isRainTire" tw="col-span-1 sm:col-span-full">
        <Checkbox />
        <div>
          <Label>Rain tire</Label>
          <CheckboxHelperText>The tested tire is a rain tire.</CheckboxHelperText>
        </div>
      </InlineFormGroup>
    </div>
  );
};

/**
 * DTO creation or update form wrapped within a Panel.
 * If the given dto has an `oid`, the dto will be created, otherwise, it will be updated.
 * @type {React.FC<{ dto?: import("./DTOQueries").DTO }>}
 */
export const DTOForm = ({ dto = {} }) => {
  const formProps = useForm({
    defaultValues: dto,
  });
  const { mutateAsync: upsertDTO, status, data } = useUpsertDTOMutation();
  const { handleSubmit } = formProps;

  return (
    <FormProvider {...formProps}>
      {data && <Redirect to="/dtos" />}
      <form onSubmit={handleSubmit(upsertDTO)}>
        <Panel>
          <PanelContent>
            <DTOFormFields tw="sm:grid-cols-3" />
          </PanelContent>
          <PanelFooter>
            <PrimaryButton type="submit" disabled={status === "loading"}>
              Save
            </PrimaryButton>
          </PanelFooter>
        </Panel>
      </form>
    </FormProvider>
  );
};

/** Form only displayed when Modal is open to allow form to be initialized with the dto defaultValues */
const DTOCreationModalForm = ({ dto, onDismiss, onCreated }) => {
  const formProps = useForm({
    mode: "onSubmit",
    defaultValues: dto,
  });
  const { handleSubmit } = formProps;

  const { mutateAsync: upsertDTO, status } = useUpsertDTOMutation();
  const onCreate = async (data) => {
    const createdDTO = await upsertDTO(data);
    onCreated(createdDTO);
  };

  return (
    <FormProvider {...formProps}>
      <form>
        <DTOFormFields tw="sm:grid-cols-2" />
        <ModalActions>
          <ModalButton
            as={PrimaryButton} 
            type="button"
            disabled={status === "loading"}
            onClick={e => {
              // handleSubmit(onValid)(e);
              handleSubmit(onCreate)(e);
            }}
          >
            Save
          </ModalButton>
          <ModalButton onClick={onDismiss} type="button">
            Cancel
          </ModalButton>
        </ModalActions>
      </form>
    </FormProvider>
  );
};

export const DTOCreationModal = ({ isOpen, dto, onDismiss, onCreated }) => {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} aria-label="DTO creation modal">
      {isOpen && <DTOCreationModalForm dto={dto} onDismiss={onDismiss} onCreated={onCreated} />}
    </Modal>
  );
};
