// @ts-check
import qs from "qs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { formatDate } from "../shared/formatDate";
import { useApi } from "../shared/useApi";

export const SESSIONS = "sessions";
export const SESSION_STINT = "Session-stints";

/**
 * @typedef SessionProps
 * @property {string} [_id]
 * @property {string} [oid]
 * @property {string} from session start date
 * @property {string} to session end date
 * @property {string} [label] session optional label
 */

/** @typedef {import("../shared/AuditHelpers").AuditedResource & SessionProps} Session */

/** @type {(searchParams?: import("../shared/QueryHelpers").SearchParams<Session>) => import("react-query").UseQueryResult<import("../shared/QueryHelpers").Page<Session>>} */
export const useSessionsQuery = (searchParams = {}) => {
  const api = useApi();
  return useQuery([SESSIONS, searchParams], async () => {
    const { limit = 0, skip = 0, sort = "-from", ...query } = searchParams;
    const response = await api.get(`v1/sessions`, {
      searchParams: qs.stringify({ limit, skip, sort, ...query }),
    });
    const totalCount = Number(response.headers.get("X-Total-Count"));
    const list = await response.json();
    return {
      totalCount,
      list,
    };
  });
};

/** @type {() => import("react-query").UseMutationResult<Session, unknown, Session>} */
export const useUpsertSessionMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (session) => {
      if (session._id) {
        return api
          .patch(`v1/sessions/${session._id}`, {
            json: session,
          })
          .json();
      }
      // The entry does not exist, create it
      return api
        .post(`v1/sessions`, {
          json: session,
        })
        .json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SESSIONS);
      },
    }
  );
};

/** @type {(sessionId: string) => import("react-query").UseQueryResult<Session>} */
export const useSessionByIdQuery = (sessionId) => {
  const api = useApi();
  return useQuery(
    [SESSIONS, sessionId],
    async () => {
      const sessions = await api
        .get(`v1/sessions`, { searchParams: { oid: sessionId, limit: 1 } })
        .json();
      if (sessions.length === 0) {
        return null;
      }
      return sessions[0];
    },
    { enabled: !!sessionId }
  );
};

/** @type {(session: Session) => string} */
export const getSessionTitle = (session) => {
  if (!session) {
    return "Without session";
  }
  return session.label ? session.label : "Unnamed session";
};

/** @type {(session: Session) => string} */
export const getSessionDescription = (session) => {
  if (!session) {
    return "Tests without session";
  }
  return `${formatDate(session.from)} - ${formatDate(session.to)}`;
};

/** @type {(session: Session) => string} */
export const getSessionShortDescription = (session) => {
  if (!session) {
    return "Tests without session";
  }
  return `${formatDate(session.from)}`;
};

/** @type {() => string} */
export const getLimitDatePastSession = () => {
  const now = new Date();
  const oneWeek = 7 * 24 * 60 * 60 * 1000;
  // A session is considered a "past session" on week after it ended
  return new Date(now.getTime() - oneWeek).toISOString();
};

/**
 * @typedef {{
 * _id: { category: string, project: string },
 * stages: number[][]
 * count: number,
 * requestedStageCount: number
 * }} SessionStints
 */

/** @type {(searchParams?: { session?: string[] }) => import("react-query").UseQueryResult<import("../shared/QueryHelpers").Page<SessionStints>>} */
export const useSessionStintsQuery = (searchParams = {}) => {
  const api = useApi();
  return useQuery([SESSION_STINT, searchParams], async () => {
    const response = await api.get(`v1/session-stints`, {
      searchParams: qs.stringify(searchParams),
    });
    const totalCount = Number(response.headers.get("X-Total-Count"));
    const list = await response.json();
    return {
      totalCount,
      list,
    };
  });
};
