/** @jsxImportSource @emotion/react */
import { DocumentAddIcon } from "@heroicons/react/solid";
import { useId } from "@reach/auto-id";
import { useMutation } from "react-query";
import "twin.macro";
import { useUpsertDTOMutation } from "../dtos/DTOQueries";
import { useMutationStatusNotification } from "../notifications/useMutationStatusNotification";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { MenuItem } from "../shared/Menu";
import { useUpsertTireMutation } from "../tires/TireQueries";

const rimDimensions = {
  "20/61-17": "8J17",
  "24/61-17": "9J17",
  "28/64-17": "11J17",
  "27/65-18": "10J18",
  "24/64-18": "9J18",
  "27/68-18": "11J18",
  "28/71-18": "12J18",
  "30/68-18": "12.5J18",
  "31/71-18": "13J18",
  "33/68-18": "12J18",
  "34/71-18": "14J18",
  "36/71-18": "14.5J18",
  "37/71-18": "14.5J18",
};

const getRimByDimension = (dimension, dto) => {
  // particular case: dimension 30/68-18 and dto starts by E419 => 12J18
  if (dimension === "30/68-18" && dto.startsWith("E419")) {
    return "12J18";
  }
  return rimDimensions[dimension];
};

export const ImportTiresFromXSLX = () => {
  const ExcelJS = require("exceljs");
  const { mutateAsync: upsertTire } = useUpsertTireMutation();
  const { mutateAsync: upsertDTO } = useUpsertDTOMutation();
  const { mutateAsync: importFile, status } = useMutation(
    async (file) => {
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(file);
      const worksheet = workbook.worksheets[0]?.id
        ? workbook.getWorksheet(workbook.worksheets[0].id)
        : undefined;

      if (!worksheet) {
        return;
      }
      const rows = [];
      worksheet.eachRow({ includeEmpty: true }, async (row, rowNumber) => {
        const tirf = row.getCell(1).value;
        const serialNumber = row.getCell(5).value;
        const dto = row.getCell(5).value?.substr(0, 8);
        const dimension = row.getCell(3).value;
        const rim = getRimByDimension(dimension, dto);
        if (rowNumber > 4 && tirf && serialNumber) {
          rows.push({
            tirf,
            dto,
            dimension,
            serialNumber,
            rim,
          });
        }
      });

      for (let row of rows) {
        const { tirf, dto: dtoValue, dimension, serialNumber, rim } = row;
        const dto = {
          value: dtoValue,
          tireDimension: dimension,
          tireRim: rim,
        };
        const tire = {
          tirf,
          dto: dtoValue,
          serialNumber,
        };

        try {
          await upsertDTO(dto);
        } catch (error) {
          console.error(`Failed to insert DTO`, dto, error);
        }

        try {
          await upsertTire(tire);
        } catch (error) {
          console.error(`Failed to insert tire`, tire, error);
        }
      }
    },
    {
      onMutate: () => {
        setTitle("Importing tires to the app...");
        setDescription("Importing tires from the XLSX file to the app");
      },
      onSuccess: () => {
        setTitle("Tires imported!");
        setDescription("Tires are imported successfully");
      },
    }
  );

  const { setTitle, setDescription } = useMutationStatusNotification(status);
  const id = useId();

  const handleImportFile = async (e) => {
    const file = e.target.files[0];
    importFile(file);
  };

  return (
    <>
      <input
        type="file"
        tw="sr-only"
        id={id}
        disabled={status === "loading"}
        onChange={handleImportFile}
      />
      <MenuItem as="label" tw="cursor-pointer" disabled={status === "loading"} htmlFor={id}>
        {status === "loading" ? <LoadingIndicator /> : <DocumentAddIcon />}
        Import tires
      </MenuItem>
    </>
  );
};
