/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { useMutation } from "react-query";
import "twin.macro";
import tw from "twin.macro";
import { DocumentOutlineIcon, XSolidIcon } from "../shared/Icons";
import { useDownloadBase64Query, useDownloadFile } from "./FileQueries";
import { humanSize } from "./humanSize";

export const FilePreviewList = styled("ul")(
  tw`grid grid-cols-2 gap-x-4 gap-y-8 sm:(grid-cols-3 gap-x-6) lg:(grid-cols-4) xl:(gap-x-8)`
);

const useDownloadRawFileMutation = () => {
  const downloadFile = useDownloadFile();
  return useMutation(async (file) => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    const url = URL.createObjectURL(await downloadFile(file.oid, file.type));
    link.href = url;
    link.click();
  });
};

export const FileLoadingIndicator = () => {
  return (
    <div tw="absolute -top-2 -right-2 w-6 h-6 inline-flex items-center justify-center rounded-full text-white bg-gray-500 ring-0 ring-offset-2">
      <svg
        tw="animate-spin h-4 w-4 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle tw="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth={2} />
        <path
          tw="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    </div>
  );
};

const ImagePreview = ({ file }) => {
  const { status: downloadStatus, data: url } = useDownloadBase64Query(file.oid, file.type, {
    format: "avif",
    width: 480,
  });

  const { status: downloadRawStatus, mutate: downloadRawFile } = useDownloadRawFileMutation();

  return (
    <>
      <button
        type="button"
        className="group"
        tw="focus:(outline-none ring-2 ring-offset-2 ring-indigo-500) block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden"
        onClick={() => downloadRawFile(file)}
      >
        {downloadStatus === "success" && (
          <img tw="group-hover:opacity-75 object-cover pointer-events-none" src={url} alt="" />
        )}
        {downloadStatus === "loading" && <div tw="bg-gray-200 object-cover animate-pulse" />}
      </button>
      {(downloadStatus === "loading" || downloadRawStatus === "loading") && (
        <FileLoadingIndicator />
      )}
    </>
  );
};

const DefaultFilePreview = ({ file }) => {
  const { status: downloadRawStatus, mutate: downloadRawFile } = useDownloadRawFileMutation();
  return (
    <>
      <button
        type="button"
        className="group"
        tw="focus:(outline-none ring-2 ring-offset-2 ring-indigo-500) block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden"
        onClick={() => downloadRawFile(file)}
      >
        <div tw="group-hover:opacity-75 pointer-events-none bg-gray-200 flex items-center justify-center">
          <DocumentOutlineIcon tw="text-gray-400 h-16 w-16" />
        </div>
      </button>
      {downloadRawStatus === "loading" && <FileLoadingIndicator />}
    </>
  );
};

/** @type {React.FC<{ file: import("../tire-tests/TireTestQueries").TireTestFile }>} */
export const FilePreview = ({ file, onDelete }) => {
  const isImage = file.type && file.type.startsWith("image/");

  return (
    <li className="group" tw="relative">
      {isImage && <ImagePreview file={file} />}
      {!isImage && <DefaultFilePreview file={file} />}

      {/* Delete button */}
      {onDelete && (
        <button
          type="button"
          tw="opacity-0 group-hover:(opacity-100) absolute -top-2 -right-2 w-6 h-6 inline-flex items-center justify-center rounded-full text-white bg-gray-500 hover:(bg-gray-600) ring-0 ring-offset-2 focus:(opacity-100 outline-none ring-2 ring-indigo-500)"
        >
          <XSolidIcon tw="h-5 w-5" onClick={() => onDelete(file)} />
          <span tw="sr-only">Delete file</span>
        </button>
      )}
      {/* File name */}
      <p tw="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
        {file.name}
      </p>
      {/* File size */}
      <p tw="block text-sm font-medium text-gray-500 pointer-events-none">
        {/* File size might not be available for older files (property was not stored on the API) */}
        {file.size !== undefined ? (
          humanSize(file.size)
        ) : (
          <span tw="inline-block min-height[1em]" />
        )}
      </p>
    </li>
  );
};
