/** @jsxImportSource @emotion/react */
import { DocumentAddIcon } from "@heroicons/react/solid";
import { useId } from "@reach/auto-id";
import { useMutation } from "react-query";
import "twin.macro";
import { useUpsertDTOMutation } from "../dtos/DTOQueries";
import { useMutationStatusNotification } from "../notifications/useMutationStatusNotification";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { MenuItem } from "../shared/Menu";
import { useFetchTireBySerialNumber, useUpsertTireMutation } from "../tires/TireQueries";

export const ImportDestroyedTiresFromXLSX = () => {
  const { mutateAsync: upsertTire } = useUpsertTireMutation();
  const { mutateAsync: upsertDTO } = useUpsertDTOMutation();
  const fetchTireBySerialNumber = useFetchTireBySerialNumber();
  const ExcelJS = require('exceljs');

  const { mutateAsync: importFile, status } = useMutation(
    async (file) => {
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(file);
      const worksheet = workbook.worksheets[0]?.id ? workbook.getWorksheet(workbook.worksheets[0].id) : undefined

      if (!worksheet) {
        return;
      }

      const rows = [];

      worksheet.eachRow({ includeEmpty: true }, async (row) => {
        const serialNumber = row.getCell(1).value;
        if (serialNumber) {
          rows.push({ serialNumber });
        }
      });

      for (let row of rows) {
        const { serialNumber } = row;

        try {
          const tire = await fetchTireBySerialNumber(serialNumber);
          if (tire) {
            await upsertTire({
              _id: tire._id,
              status: "destroyed",
            });
          } else {
            const dtoValue = serialNumber.substr(0, 8);
            const dto = {
              value: dtoValue,
            };
            const tireToInsert = {
              dto: dtoValue,
              serialNumber,
              status: "destroyed"
            };
            try {
              await upsertDTO(dto);
            } catch (error) {
            }
    
            try {
              await upsertTire(tireToInsert);
            } catch (error) {
              console.error(`Failed to insert tire`, tire, error);
            }
          }
        } catch (error) {
          console.error(`Failed to update tire`, serialNumber, error);
        }
      }
    },
    {
      onMutate: () => {
        setTitle("Importing destroyed tires to the app...");
        setDescription("Importing destroyed tires from the XLSX file to the app");
      },
      onSuccess: () => {
        setTitle("Destroyed tires imported!");
        setDescription("The destroyed tires are imported successfully");
      },
    }
  );

  const { setTitle, setDescription } = useMutationStatusNotification(status);
  const id = useId();

  const handleImportFile = async (e) => {
    const file = e.target.files[0];
    importFile(file);
  };

  return (
    <>
      <input
        type="file"
        tw="sr-only"
        id={id}
        disabled={status === "loading"}
        onChange={handleImportFile}
      />
      <MenuItem as="label" tw="cursor-pointer" disabled={status === "loading"} htmlFor={id}>
        {status === "loading" ? <LoadingIndicator /> : <DocumentAddIcon />}
        Import destroyed tires
      </MenuItem>
    </>
  );
};
